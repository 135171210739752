@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* background-image: url("./img/header-background.png"); */
  /* background-color: azure;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(240, 238, 238, 1) 85%,
    rgba(241, 241, 241, 1) 100%
  );

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.beautyModal {
  background: linear-gradient(251deg, #a626d5, #2f083e, #a52ad2);
  background-size: 600% 600%;

  -webkit-animation: AnimatedGradientBg 23s ease infinite;
  -moz-animation: AnimatedGradientBg 23s ease infinite;
  animation: AnimatedGradientBg 23s ease infinite;
}

@-webkit-keyframes AnimatedGradientBg {
  0% {
    background-position: 0% 72%;
  }
  50% {
    background-position: 100% 29%;
  }
  100% {
    background-position: 0% 72%;
  }
}
@-moz-keyframes AnimatedGradientBg {
  0% {
    background-position: 0% 72%;
  }
  50% {
    background-position: 100% 29%;
  }
  100% {
    background-position: 0% 72%;
  }
}
@keyframes AnimatedGradientBg {
  0% {
    background-position: 0% 72%;
  }
  50% {
    background-position: 100% 29%;
  }
  100% {
    background-position: 0% 72%;
  }
}
